/* Modal */
.modal{
    background:rgba(0, 0, 0, 0.5);
}

.modal-content{
    border:none;
    border-radius: 0;

    .modal-body{
        padding:$padding-column;

        .close{
            &:hover{
                color:$blue-color;
            }
        }
    }
}

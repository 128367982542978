/* ============================================== */
/* Author : Adam Muhammad Nurdin
/* Email  : adamnurdin0@gmail.com
/* Project  : corenav
/* version  : 1.1.3
/* Description  : coreNavigation is a multi purpose navigation menu for javascript library based on jquery, come with more style and easy to combination.
/* ============================================== */

.core-content {
	-webkit-transition: all .5s ease-in-out;
	-khtml-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
}

.core-content .brand {
	outline: 0;
}

.core-content .brand img {
	border: 0;
	outline: 0;
}

.core-content .side-menu {
	position: fixed;
	overflow-y: auto;
	top: 0;
	right: -280px;
	width: 280px;
	height: 100%;
	display: block;
	border-left: solid 1px #efefef;
	-webkit-transition: all .5s ease-in-out;
	-khtml-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
	z-index: 9;
}

.core-content.open-side-menu {
	margin-left: -280px;
	margin-right: 280px;
}

.core-content.open-side-menu .side-menu {
	right: 0;
}

.core-content.open-side-menu nav.nav-core-sticky.on-scroll {
	margin-left: -280px;
	margin-right: 280px;
}

.core-content .wrap-search-top {
	display: none;
	border-bottom: solid 1px #efefef;
}

.core-content .wrap-search-top input {
	border: 0;
	padding: 15px;
	display: block;
	width: 100%;
	outline: none;
	font-size: 14px;
	color: #6a6a6a;
}

.core-content .wrap-search-top ::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	font-size: 14px;
	font-style: italic;
}

.core-content .wrap-search-top ::-moz-placeholder {
	/* Firefox 19+ */
	font-size: 14px;
	font-style: italic;
}

.core-content .wrap-search-top :-ms-input-placeholder {
	/* IE 10+ */
	font-size: 14px;
	font-style: italic;
}

.core-content .wrap-search-top :-moz-placeholder {
	/* Firefox 18- */
	font-size: 14px;
	font-style: italic;
}

.core-content .wrap-search-fullscreen {
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	display: none;
}

.core-content .wrap-search-fullscreen .nav-container {
	position: relative;
	padding-top: 50px;
	padding-left: 15px;
	padding-right: 15px;
}

.core-content .wrap-search-fullscreen.open {
	display: block;
}

.core-content .wrap-search-fullscreen .close-search {
	position: absolute;
	right: 0;
	top: 15px;
	padding: 15px;
	border: 0;
	background: transparent;
	outline: 0;
	cursor: pointer;
	color: #6a6a6a;
	z-index: 3;
	font-size: 24px;
}

.core-content .wrap-search-fullscreen input {
	display: block;
	width: 100%;
	border: 0;
	border-bottom: solid 1px #efefef;
	padding: 15px;
	outline: 0;
	font-size: 24px;
}


/*
Core Navigation
=========================== */

.core-nav {
	display: block;
	position: relative;
}

.core-nav.open-dropdown {
	z-index: 99999999;
}

.core-nav .nav-header {
	display: block;
	position: absolute;
	z-index: 2;
}

.core-nav .nav-header.left {
	float: left;
	position: relative;
	display: inline-block;
}

.core-nav .nav-header.center .brand {
	display: block;
	margin: auto;
}

.core-nav .nav-header .content-header {
	float: right;
}

.core-nav .core-nav-toggle {
	display: none;
	border: 0;
	padding: 0;
	background: transparent;
	outline: none;
	cursor: pointer;
}

.core-nav .wrap-core-nav-list {
	text-align: left;
	position: relative;
	z-index: 1;
}

.core-nav .wrap-core-nav-list.right {
	text-align: right;
}

.core-nav .wrap-core-nav-list.center {
	text-align: center;
}

.core-nav .wrap-core-nav-list .core-nav-list {
	display: inline-block;
	margin: 0 0 -5px 0;
	padding: 0;
	list-style: none;
}

.core-nav .wrap-core-nav-list .core-nav-list li {
	display: block;
	float: left;
}

.core-nav.nav-core-fixed {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
}

.core-nav.nav-core-sticky {
	position: relative;
}

.core-nav.nav-core-sticky.on-scroll {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}

@media (min-width: 992px) {
	.core-nav .nav-header.bottom {
		position: relative;
		float: none;
		display: block;
		width: 100%;
	}
	.core-nav .wrap-core-nav-list.bottom {
		position: relative;
		float: none;
		display: block;
		width: 100%;
	}
}

@media (max-width: 992px) {
	.core-nav .nav-header {
		position: relative;
		width: 100%;
		display: block;
	}
	.core-nav .nav-header.left {
		float: none;
		display: block;
		position: relative;
	}
	.core-nav .nav-header::after {
		content: "";
		clear: both;
		display: table;
	}
	.core-nav .nav-header .content-header {
		float: none;
	}
	.core-nav .core-nav-toggle {
		display: inline-block;
		float: left;
	}
	.core-nav .wrap-core-nav-list {
		width: 100%;
		max-height: 300px;
		overflow: auto;
		left: 0;
		margin-top: -100vh;
		position: absolute;
		border-bottom: solid 1px #dfdfdf;
		border-top: solid 1px #dfdfdf;
		filter: alpha(opacity=0);
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		-webkit-opacity: 0;
		-khtml-opacity: 0;
		-moz-opacity: 0;
		-ms-opacity: 0;
		-o-opacity: 0;
		opacity: 0;
		-webkit-transition: all .5s ease-in-out;
		-khtml-transition: all .5s ease-in-out;
		-moz-transition: all .5s ease-in-out;
		-ms-transition: all .5s ease-in-out;
		-o-transition: all .5s ease-in-out;
		transition: all .5s ease-in-out;
	}
	.core-nav .wrap-core-nav-list .core-nav-list {
		margin: 0;
		display: block;
	}
	.core-nav .wrap-core-nav-list .core-nav-list li {
		float: none;
		display: block;
		width: 100%;
	}
	.core-nav .wrap-core-nav-list .core-nav-list li a {
		display: block;
		width: 100%;
		text-align: left;
	}
	.core-nav .nav-header.bottom {
		border: 0;
	}
	.core-nav.open-responsive .wrap-core-nav-list {
		margin-top: 0;
		filter: alpha(opacity=100);
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		-webkit-opacity: 1;
		-khtml-opacity: 1;
		-moz-opacity: 1;
		-ms-opacity: 1;
		-o-opacity: 1;
		opacity: 1;
	}
	.core-responsive-slide {
		-webkit-transition: all .5s ease-in-out;
		-khtml-transition: all .5s ease-in-out;
		-moz-transition: all .5s ease-in-out;
		-ms-transition: all .5s ease-in-out;
		-o-transition: all .5s ease-in-out;
		transition: all .5s ease-in-out;
		filter: alpha(opacity=100);
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		-webkit-opacity: 1;
		-khtml-opacity: 1;
		-moz-opacity: 1;
		-ms-opacity: 1;
		-o-opacity: 1;
		opacity: 1;
	}
	.core-responsive-slide .core-nav .wrap-core-nav-list {
		top: 0;
		width: 270px;
		max-height: 100vh;
		height: 100vh;
		margin-top: 0;
		margin-left: -270px;
		border: 0;
		border-right: solid 1px #dfdfdf;
		position: fixed;
		-webkit-transition: all .5s ease-in-out;
		-khtml-transition: all .5s ease-in-out;
		-moz-transition: all .5s ease-in-out;
		-ms-transition: all .5s ease-in-out;
		-o-transition: all .5s ease-in-out;
		transition: all .5s ease-in-out;
	}
	.core-responsive-slide.open {
		margin-left: 270px;
		margin-right: -270px;
	}
	.core-responsive-slide.open .core-nav .wrap-core-nav-list {
		top: 0;
		margin-top: 0;
		margin-left: 0;
		filter: alpha(opacity=100);
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		-webkit-opacity: 1;
		-khtml-opacity: 1;
		-moz-opacity: 1;
		-ms-opacity: 1;
		-o-opacity: 1;
		opacity: 1;
	}
}


/*
Fullscreen
=========================== */

@media (min-width: 920px) {
	.core-nav.fullscreen .nav-header {
		width: 100%;
		text-align: left;
		padding: 0;
		position: relative;
	}
	.core-nav.fullscreen .nav-header .brand {
		top: 0;
		margin: 15px;
	}
	.core-nav.fullscreen .core-nav-toggle {
		position: absolute;
		right: 15px;
		top: 15px;
		font-size: 20px;
		display: inline-block;
	}
	.core-nav.fullscreen .wrap-core-nav-list {
		display: none;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 99999;
		background-color: #fff;
	}
	.core-nav.fullscreen .wrap-core-nav-list.center {
		text-align: center;
	}
	.core-nav.fullscreen .wrap-core-nav-list.right {
		text-align: right;
	}
	.core-nav.fullscreen .wrap-core-nav-list .nav-container {
		height: 100vh;
		display: table;
		position: relative;
		padding: 0 30px;
	}
	.core-nav.fullscreen .wrap-core-nav-list .menu {
		display: table-cell;
		vertical-align: middle;
	}
	.core-nav.fullscreen .wrap-core-nav-list .menu li {
		float: none;
		display: block;
	}
	.core-nav.fullscreen.open-fullscreen .core-nav-toggle {
		right: 30px;
	}
	.core-nav.fullscreen.open-fullscreen .wrap-core-nav-list {
		display: block;
	}
}

@media (max-width: 920px) {
	.core-nav.fullscreen .wrap-core-nav-list .nav-container .core-nav-toggle {
		display: none;
	}
}

.core-content {
	min-height: 100vh;
}


/*
Sidebar
=========================== */

@media (min-width: 992px) {
	.core-content {
		position: relative;
		min-height: 100vh;
	}
	.core-content.core-sidebar {
		padding-left: 270px;
	}
	.core-content.core-sidebar .dropdown-overlay {
		left: 270px;
	}
	.core-content.core-sidebar ul.attributes {
		position: fixed;
		width: 270px;
		left: 0;
		bottom: 0;
		display: flex;
		justify-content: space-around;
		z-index: 3;
	}
	.nav-sidebar {
		width: 270px;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 270px !important;
		border-right: solid 1px #dfdfdf;
	}
	.nav-sidebar .nav-header {
		display: block;
		width: 100%;
	}
	.nav-sidebar .nav-header .brand {
		display: block;
	}
	.nav-sidebar .nav-header .brand img {
		width: 100%;
	}
	.nav-sidebar .wrap-core-nav-list .core-nav-list {
		display: block;
	}
	.nav-sidebar .wrap-core-nav-list .core-nav-list li {
		display: block;
		float: none;
	}
}

@media (max-width: 992px) {
	.nav-sidebar {
		border-right: 0;
	}
	.nav-sidebar .nav-header .nav-container {
		padding-left: 0;
		padding-right: 0;
	}
	.nav-sidebar .wrap-core-nav-list {
		padding: 0;
		border-bottom: solid 1px #dfdfdf;
	}
	.nav-sidebar .wrap-core-nav-list .nav-container {
		padding-left: 0;
		padding-right: 0;
	}
	.core-content.core-sidebar.core-responsive-slide nav.core-nav.nav-core-fixed.nav-sidebar {
		-webkit-transition: all .5s ease-in-out;
		-khtml-transition: all .5s ease-in-out;
		-moz-transition: all .5s ease-in-out;
		-ms-transition: all .5s ease-in-out;
		-o-transition: all .5s ease-in-out;
		transition: all .5s ease-in-out;
	}
	.core-content.core-sidebar.core-responsive-slide.open nav.core-nav.nav-core-fixed.nav-sidebar {
		margin-left: 270px;
		margin-right: -270px;
	}
	.core-content.core-sidebar.core-responsive-slide.open .dropdown-overlay.open-dropdown {
		left: 270px;
	}
}


/*
Brand Center
=========================== */

@media (min-width: 920px) {
	.core-nav.brand-center .full-container,
	.core-nav.brand-center .nav-container {
		position: relative;
	}
	.core-nav.brand-center .nav-header {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
	}
	.core-nav.brand-center .nav-header .brand {
		margin: auto;
	}
	.core-nav.brand-center .wrap-core-nav-list .col-menu {
		width: 50%;
		display: inline-block;
	}
	.core-nav.brand-center .wrap-core-nav-list .col-menu.right {
		text-align: right;
	}
	.core-nav.brand-center .wrap-core-nav-list.center .col-menu.left {
		text-align: right;
		padding-right: 50px;
	}
	.core-nav.brand-center .wrap-core-nav-list.center .col-menu.right {
		text-align: left;
		padding-left: 50px;
	}
}

.core-content {
	min-height: 100vh;
}


/*
Sidebar Toggle
=========================== */

@media (min-width: 992px) {
	.core-content {
		position: relative;
		min-height: 100vh;
	}
	.core-content.core-sidebar-toggle {
		padding-left: 75px;
	}
	.sidebar-toggle {
		width: 75px;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		border-right: solid 1px #dfdfdf;
	}
	.sidebar-toggle .nav-header {
		display: block;
		width: 100%;
		height: 100vh;
	}
	.sidebar-toggle .nav-header .brand {
		display: block;
	}
	.sidebar-toggle .nav-header .brand img {
		width: 100%;
	}
	.sidebar-toggle .core-nav-toggle {
		display: block;
		width: 75px;
		height: 75px;
		text-align: center;
		position: absolute;
		top: 50%;
		left: 0;
		font-size: 18px;
		color: #6a6a6a;
	}
	.sidebar-toggle .wrap-core-nav-list {
		position: absolute;
		height: 100%;
		width: 270px;
		margin-left: -270px;
		border-right: solid 1px #dfdfdf;
		-webkit-transition: all .5s ease-in-out;
		-khtml-transition: all .5s ease-in-out;
		-moz-transition: all .5s ease-in-out;
		-ms-transition: all .5s ease-in-out;
		-o-transition: all .5s ease-in-out;
		transition: all .5s ease-in-out;
	}
	.sidebar-toggle .wrap-core-nav-list .core-nav-list {
		display: block;
	}
	.sidebar-toggle .wrap-core-nav-list .core-nav-list li {
		display: block;
		float: none;
	}
	.sidebar-toggle .wrap-core-nav-list .core-nav-list li a {
		border-bottom: solid 1px #dfdfdf;
	}
	.sidebar-toggle .wrap-core-nav-list.full-width {
		width: calc(100vw - 75px);
		margin-left: -100vw;
	}
	.sidebar-toggle .wrap-core-nav-list.full-width .core-nav-list {
		display: inline-block;
		width: 100%;
		margin: 0;
	}
	.sidebar-toggle .wrap-core-nav-list.full-width .core-nav-list li {
		background: #fafafa;
		float: left;
		display: table;
		height: 100vh;
		text-align: center;
		vertical-align: middle;
	}
	.sidebar-toggle .wrap-core-nav-list.full-width .core-nav-list li a {
		border: 0;
		height: 100vh;
		display: table-cell;
		vertical-align: middle;
		border-right: solid 1px #dfdfdf;
	}
	.sidebar-toggle.open-responsive .wrap-core-nav-list {
		margin-left: 75px;
	}
}

.core-nav-section .core-nav {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
}

@media (min-width: 920px) {
	.core-nav-section .core-nav {
		height: 100%;
		background: transparent;
	}
	.core-nav-section .core-nav .nav-header {
		padding: 50px;
	}
	.core-nav-section .core-nav .nav-header .brand {
		width: 100px;
		display: block;
	}
	.core-nav-section .core-nav .nav-header .brand img {
		width: 100%;
	}
	.core-nav-section .core-nav .core-nav-list {
		display: block;
		background: transparent;
		position: absolute;
		padding-left: 50px;
		width: 150px;
	}
	.core-nav-section .core-nav .core-nav-list li {
		float: none;
		display: block;
	}
	.core-nav-section .core-nav .core-nav-list li a {
		background: transparent;
		color: #6a6a6a;
		text-decoration: none;
		margin: 15px 0;
		display: block;
		font-size: 14px;
	}
	.core-nav-section .core-nav .core-nav-list li.active a {
		font-size: 18px;
		color: #e74c3c;
	}
}

@media (max-width: 920px) {
	.core-nav-section .core-nav {
		width: 100%;
	}
}

.core-content {
	min-height: 100vh;
}


/*
Side Icon
=========================== */

@media (min-width: 992px) {
	.core-content {
		position: relative;
		min-height: 100vh;
	}
	.core-content.core-side-icon {
		padding-left: 75px;
	}
	.core-content .nav-side-icon {
		-webkit-transition: all .5s ease-in-out;
		-khtml-transition: all .5s ease-in-out;
		-moz-transition: all .5s ease-in-out;
		-ms-transition: all .5s ease-in-out;
		-o-transition: all .5s ease-in-out;
		transition: all .5s ease-in-out;
		width: 75px;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		border-right: solid 1px #dfdfdf;
	}
	.core-content .nav-side-icon .nav-header {
		display: block;
		width: 100%;
	}
	.core-content .nav-side-icon .nav-header .brand {
		display: block;
	}
	.core-content .nav-side-icon .nav-header .brand img {
		width: 100%;
	}
	.core-content .nav-side-icon .wrap-core-nav-list {
		display: block;
		position: relative;
		margin-top: 100px;
	}
	.core-content .nav-side-icon .wrap-core-nav-list ul.core-nav-list {
		display: block;
	}
	.core-content .nav-side-icon .wrap-core-nav-list ul.core-nav-list>li {
		float: none;
		display: block;
		width: 100%;
		position: relative;
	}
	.core-content .nav-side-icon .wrap-core-nav-list ul.core-nav-list>li>a {
		display: block;
		width: 100%;
		text-align: center;
		padding: 10px 5px;
	}
	.core-content .nav-side-icon .wrap-core-nav-list ul.core-nav-list>li.dropdown>ul.dropdown-menu {
		top: 0;
		left: 100%;
	}
	.core-content .nav-side-icon .wrap-core-nav-list ul.core-nav-list .text-icon {
		display: none;
	}
	.core-content.open-side-icon {
		padding-left: 250px;
	}
	.core-content.open-side-icon .nav-side-icon {
		width: 250px;
	}
	.core-content.open-side-icon .wrap-core-nav-list ul.core-nav-list>li>a {
		position: relative;
		text-align: left;
	}
	.core-content.open-side-icon .wrap-core-nav-list ul.core-nav-list .icon {
		position: absolute;
		left: 20px;
		top: 12px;
	}
	.core-content.open-side-icon .wrap-core-nav-list ul.core-nav-list .text-icon {
		display: inline-block;
		margin-left: 40px;
	}
}

@media (max-width: 992px) {
	.nav-side-icon .wrap-core-nav-list li {
		position: relative;
	}
	.nav-side-icon .wrap-core-nav-list li .icon {
		position: absolute;
		left: 10px;
		top: 12px;
	}
	.nav-side-icon .wrap-core-nav-list li .text-icon {
		display: inline-block;
		margin-left: 25px;
	}
}


/*
Dropdown
=========================== */

.dropdown-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	display: none;
	background: #fff;
	left: 0;
	top: 0;
	filter: alpha(opacity=0);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-opacity: 0;
	-khtml-opacity: 0;
	-moz-opacity: 0;
	-ms-opacity: 0;
	-o-opacity: 0;
	opacity: 0;
}

.dropdown-overlay.open-dropdown {
	display: block;
	z-index: 9999999;
}

.core-nav .dropdown {
	position: relative;
}

.core-nav .dropdown .dropdown-menu {
	display: none;
}

.core-nav .dropdown>.dropdown-menu {
	width: 230px;
	background: #fff;
	border: solid 1px #eee;
	position: absolute;
	top: 100%;
	padding: 0;
}

.core-nav .dropdown>.dropdown-menu>li {
	float: none;
	display: block;
}

.core-nav .dropdown>.dropdown-menu>li>a {
	border-bottom: solid 1px #eee;
	padding: 10px 15px;
	text-align: left;
}

.core-nav .dropdown.open>.dropdown-menu {
	display: block;
}

.core-nav .wrap-core-nav-list.center .dropdown>.dropdown-menu,
.core-nav .wrap-core-nav-list.left .dropdown>.dropdown-menu {
	left: 0;
}

.core-nav .wrap-core-nav-list.center .dropdown>.dropdown-menu .dropdown>.dropdown-menu,
.core-nav .wrap-core-nav-list.left .dropdown>.dropdown-menu .dropdown>.dropdown-menu {
	top: 0;
	left: 100%;
}

.core-nav .wrap-core-nav-list.right .dropdown>.dropdown-menu {
	right: 0;
}

.core-nav .wrap-core-nav-list.right .dropdown>.dropdown-menu .dropdown>.dropdown-menu {
	top: 0;
	right: 100%;
}

.core-nav .wrap-core-nav-list.dropdown-accordion .core-nav-list li.dropdown>.dropdown-menu {
	position: relative;
	width: 100%;
	border: 0;
	padding-left: 15px;
}

.core-nav .wrap-core-nav-list.dropdown-accordion .core-nav-list>li.dropdown>.dropdown-menu {
	position: absolute !important;
	width: 230px;
	border: solid 1px #efefef;
	padding-left: 0;
}

.core-nav .wrap-core-nav-list.dropdown-accordion.center .dropdown>.dropdown-menu .dropdown>.dropdown-menu,
.core-nav .wrap-core-nav-list.dropdown-accordion.left .dropdown>.dropdown-menu .dropdown>.dropdown-menu {
	left: 0;
}

.core-nav .wrap-core-nav-list.dropdown-accordion.right .dropdown>.dropdown-menu .dropdown>.dropdown-menu {
	right: 0;
}

.core-sidebar-toggle .core-nav .wrap-core-nav-list.center>.core-nav-list>li.dropdown>ul.dropdown-menu,
.core-sidebar-toggle .core-nav .wrap-core-nav-list.left>.core-nav-list>li.dropdown>ul.dropdown-menu,
.core-sidebar-toggle .core-nav .wrap-core-nav-list.right>.core-nav-list>li.dropdown>ul.dropdown-menu,
.core-sidebar .core-nav .wrap-core-nav-list.center>.core-nav-list>li.dropdown>ul.dropdown-menu,
.core-sidebar .core-nav .wrap-core-nav-list.left>.core-nav-list>li.dropdown>ul.dropdown-menu,
.core-sidebar .core-nav .wrap-core-nav-list.right>.core-nav-list>li.dropdown>ul.dropdown-menu {
	left: 100%;
	top: 0;
}

.core-sidebar-toggle .core-nav .wrap-core-nav-list.dropdown-accordion .core-nav-list>li.dropdown>.dropdown-menu,
.core-sidebar .core-nav .wrap-core-nav-list.dropdown-accordion .core-nav-list>li.dropdown>.dropdown-menu {
	left: 0 !important;
	position: relative !important;
	width: 100%;
	border: solid 1px #efefef;
	padding-left: 0;
}

@media (max-width: 920px) {
	.core-nav .dropdown .dropdown-menu {
		position: relative;
		width: 100%;
		border: 0;
		padding-left: 15px;
		top: 0;
		left: 0 !important;
		right: 0 !important;
	}
	.core-nav ul.attributes .dropdown .dropdown-menu {
		border: solid 1px #efefef;
		padding: 0;
		position: absolute;
		display: none;
		top: 100%;
		left: 0;
		right: 0;
		width: 230px;
	}
	.core-nav ul.attributes .dropdown.open .dropdown-menu {
		display: block;
	}
	.core-nav .wrap-core-nav-list.dropdown-accordion .core-nav-list>li.dropdown>a {
		display: block;
		position: relative;
	}
	.core-nav .wrap-core-nav-list.dropdown-accordion .core-nav-list>li.dropdown .dropdown-menu {
		position: relative !important;
		width: 100% !important;
	}
	.core-nav .wrap-core-nav-list.dropdown-accordion .core-nav-list>li.dropdown.open>.dropdown-menu {
		display: block;
		position: relative;
		border: 0 !important;
		padding-left: 15px;
	}
}


/*
Mega Menu
=========================== */

.core-nav .megamenu>.megamenu-content {
	position: absolute;
	top: 100%;
	width: 100%;
	background: #fff;
	text-align: left;
	border: solid 1px #efefef;
	display: none;
}

.core-nav .megamenu.open>.megamenu-content {
	display: block;
}

.core-nav .wrap-core-nav-list.center .megamenu>.megamenu-content,
.core-nav .wrap-core-nav-list.left .megamenu>.megamenu-content {
	left: 0;
}

.core-nav .wrap-core-nav-list.right .megamenu>.megamenu-content {
	right: 0;
}

@media (max-width: 920px) {
	.core-nav .megamenu>.megamenu-content {
		position: relative;
		width: 100% !important;
		border: 0;
		border-bottom: solid 1px #efefef;
	}
	.core-nav ul.attributes .megamenu .megamenu-content {
		border: solid 1px #efefef;
		padding: 0;
		position: absolute;
		display: none;
		width: 300px !important;
		top: 100%;
		left: 0;
		right: 0;
	}
	.core-nav ul.attributes .megamenu.open .megamenu-content {
		display: block;
	}
}

ul.attributes {
	padding: 0;
	margin: 0 0 -7px 0;
	list-style: none;
	display: inline-block;
	float: right;
}

ul.attributes li {
	float: left;
	display: block;
}

ul.attributes li a {
	display: block;
	padding: 20px 10px;
	color: #6a6a6a;
}

ul.attributes li a:hover {
	color: #e74c3c;
}

.nav-header ul.attributes {
	position: relative;
	z-index: 9;
	margin-right: 10px;
}

.nav-header ul.attributes li a {
	padding: 20px 8px;
}

@media (min-width: 920px) {
	.nav-header ul.attributes {
		display: none;
	}
	.wrap-core-nav-listul.attributes {
		display: inline-block;
	}
}

@media (max-width: 920px) {
	.nav-header ul.attributes {
		display: inline-block;
	}
	.wrap-core-nav-list ul.attributes {
		display: none;
	}
}


/*
Grid
=========================== */

* {
	box-sizing: border-box;
}

.core-nav {
	/*
    Row
    =========================== */
	/*
    Container
    =========================== */
}

.core-nav .nav-row::after {
	content: "";
	clear: both;
	display: table;
}

.core-nav .nav-row [class*="col-"] {
	float: left;
	padding: 15px;
}

.core-nav .nav-container {
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 768px) {
	.core-nav .nav-row .col-sm-1 {
		width: 8.33%;
	}
	.core-nav .nav-row .col-sm-2 {
		width: 16.66%;
	}
	.core-nav .nav-row .col-sm-3 {
		width: 25%;
	}
	.core-nav .nav-row .col-sm-4 {
		width: 33.33%;
	}
	.core-nav .nav-row .col-sm-5 {
		width: 41.66%;
	}
	.core-nav .nav-row .col-sm-6 {
		width: 50%;
	}
	.core-nav .nav-row .col-sm-7 {
		width: 58.33%;
	}
	.core-nav .nav-row .col-sm-8 {
		width: 66.66%;
	}
	.core-nav .nav-row .col-sm-9 {
		width: 75%;
	}
	.core-nav .nav-row .col-sm-10 {
		width: 83.33%;
	}
	.core-nav .nav-row .col-sm-11 {
		width: 91.66%;
	}
	.core-nav .nav-row .col-sm-12 {
		width: 100%;
	}
	.core-nav .nav-container {
		width: 750px;
	}
}

@media (min-width: 992px) {
	.core-nav .nav-row .col-md-1 {
		width: 8.33%;
	}
	.core-nav .nav-row .col-md-2 {
		width: 16.66%;
	}
	.core-nav .nav-row .col-md-3 {
		width: 25%;
	}
	.core-nav .nav-row .col-md-4 {
		width: 33.33%;
	}
	.core-nav .nav-row .col-md-5 {
		width: 41.66%;
	}
	.core-nav .nav-row .col-md-6 {
		width: 50%;
	}
	.core-nav .nav-row .col-md-7 {
		width: 58.33%;
	}
	.core-nav .nav-row .col-md-8 {
		width: 66.66%;
	}
	.core-nav .nav-row .col-md-9 {
		width: 75%;
	}
	.core-nav .nav-row .col-md-10 {
		width: 83.33%;
	}
	.core-nav .nav-row .col-md-11 {
		width: 91.66%;
	}
	.core-nav .nav-row .col-md-12 {
		width: 100%;
	}
	.core-nav .nav-container {
		width: 970px;
	}
	.core-nav .full-container {
		padding: 0 15px;
	}
}

@media (min-width: 1200px) {
	.core-nav .nav-container {
		width: 1170px;
		padding-right: 15px;
		padding-left: 15px;
	}
}

@media (max-width: 767px) {
	.core-nav .nav-row .col-xs-1 {
		width: 8.33%;
	}
	.core-nav .nav-row .col-xs-2 {
		width: 16.66%;
	}
	.core-nav .nav-row .col-xs-3 {
		width: 25%;
	}
	.core-nav .nav-row .col-xs-4 {
		width: 33.33%;
	}
	.core-nav .nav-row .col-xs-5 {
		width: 41.66%;
	}
	.core-nav .nav-row .col-xs-6 {
		width: 50%;
	}
	.core-nav .nav-row .col-xs-7 {
		width: 58.33%;
	}
	.core-nav .nav-row .col-xs-8 {
		width: 66.66%;
	}
	.core-nav .nav-row .col-xs-9 {
		width: 75%;
	}
	.core-nav .nav-row .col-xs-10 {
		width: 83.33%;
	}
	.core-nav .nav-row .col-xs-11 {
		width: 91.66%;
	}
	.core-nav .nav-row .col-xs-12 {
		width: 100%;
	}
}
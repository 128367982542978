/* Navbar dropdown */
.core-nav .wrap-core-nav-list.right .dropdown > .dropdown-menu{
    margin-top:0;
    border-radius:0;
    box-shadow:none;
    border:1px solid $gray-color;
    border-bottom: none;
    
    li{
        padding:0;
        a{
            display:block;
            padding:$padding-half $padding-text!important;
            font-family: $font-montserrat;
            border-bottom:1px solid $gray-color;

            &::after{
                display: none;
                margin-left:0;
                padding-top:0;
            }

            &:hover{
                background: $dropdown-hover-bg;
                @media #{$media-not-desktop} {
                    background: none;
                }
            }
        }
        &.dropdown{
            position: relative;

            &::after{
                position: absolute;
                content: "\f0d7";
                font-family: $font-awesome;
                font-size:11px;
                top:50%;
                right:$padding-text;
                margin-top:-12px;
            }
        }
    }
}
.core-nav .wrap-core-nav-list.right .dropdown > .dropdown-menu .dropdown > .dropdown-menu {
    top: 0;
    left:auto;
    right: 100% !important;
}

.header .menu li.active .dropdown-menu li a{
    color: $black-color;

    &:hover{
        color: $blue-color;
    }
}

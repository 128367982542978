.breadcrumb{
    background: none;
    padding: 0;
    margin: 0;
    border-radius: 0;

    .breadcrumb-item{

        +.breadcrumb-item{
            &::before{
                color: white;
            }
        }

        a{
            color: white;
        }

        i{
            margin-right: 5px;
        }

        &.active{
            color: white;
            opacity: 0.6;
        }
    }
}
/* Pricing */
.pricing{
    float: left;
    width: 100%;
    position: relative;
    margin-bottom:$padding-component;
    @media #{$media-not-desktop} {
        margin-bottom:calc($padding-component/2);
    }
    &__column{
        position:relative;
        display:block;
        text-align:center;
        border:1px solid transparent;
        border-radius: 4px;
        float: left;
        width:31%;
        margin-top:$padding-text;
        padding: $padding-elements $padding-text;
        @media #{$media-not-desktop} {
            width:100%;
            margin-top:0;
            padding: calc($padding-elements/2)+10 calc($padding-text/2);
            margin-bottom:calc($padding-elements/2);
            &:last-child{
                margin-bottom:0;
            }
        }

        &.pp-first{
            border-right:none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            @media #{$media-not-desktop} {
                border-width:1px;
                border-style: solid;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }

        }
        &.pp-last{
            border-left:none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            @media #{$media-not-desktop} {
                border-width:1px;
                border-style: solid;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }
    }

    &__heading{
        h3{
            margin-bottom:$padding-print;
            @media #{$media-not-desktop} {
                margin-bottom:calc($padding-print/2);
            }
        }
    }

    &__icon{
        font-size:42px;
        margin-bottom:$padding-print;
        @media #{$media-not-desktop} {
            font-size:36px;
            margin-bottom:calc($padding-print/2);
        }
    }

    &__content{
        margin-bottom:$padding-text;
        @media #{$media-not-desktop} {
            margin-bottom:calc($padding-text/2);
        }
        p{
            @extend %h4;
            font-family: $font-montserrat-light;
            margin-bottom:$padding-print - 5;
            @media #{$media-not-desktop} {
                margin-bottom:0;
            }
        }

        &-price{
            @extend %h1;
            position: relative;
            font-family: $font-montserrat-light;
            padding-left:$padding-print;

        }

        &-currency{
            @extend %h5;
            position: absolute;
            font-family: $font-montserrat-light;
            line-height:auto;
            font-style: normal;
            margin-top:5px;
            left:0;
        }

        &-package{
            @extend %h5;
            font-family: $font-montserrat-light; 
        }
    }

    &__popular{
        position: relative;
        width:38%;
        margin-top:0;
        padding: $padding-component $padding-text $padding-component - 10 $padding-text;
        z-index:4;
        border-radius: 8px;
        border-width: 3px;
        border-color:$blue-color;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
        @media #{$media-not-desktop} {
            width:100%;
            padding: calc($padding-elements/2)+10 calc($padding-text/2);
        }

        .pricing__icon{
            font-size:72px;
            color:$blue-color;
            @media #{$media-not-desktop} {
                font-size:60px;
            }
        }

        .pricing__heading{
            margin-bottom:$padding-text;
            @media #{$media-not-desktop} {
                margin-bottom:calc($padding-text/2);
            }
        }

        .pricing__content{
 
            p{
                @extend %h3;
                font-family: $font-montserrat-light;
                margin-bottom:$padding-print;
                @media #{$media-not-desktop} {
                    margin-bottom:calc($padding-print/2);
                }
            }
        }

        .pricing__content-price{
            font-size:54px;
            @media #{$media-not-desktop} {
                font-size:42px;
            }
        }

        .pricing__content-currency{
            @extend %h4;
            font-family: $font-montserrat-light;
        }

        .pricing__content-package{
            @extend %h4;
            font-family: $font-montserrat-light; 
        }

        $padding_component_min: calc($padding-component * -1);
        .pricing__action{
            position: absolute;
            width:100%;
            left:0;
            bottom: calc($padding_component_min/2);
            @media #{$media-not-desktop} {
                position: relative;
                left:auto;
                bottom:auto;
            }
        }

        .btn{
            padding: $padding-text $padding-elements+5;
            background:$white-bg;
            @media #{$media-not-desktop} {
                padding: calc($padding-half/2)+5 calc($padding-elements/2)+5;
            }
        }
    }

    &__green{
        border-color:$green-color;
        .pricing__icon{
            color:$green-color;
        }

        .pricing__content-package{
            color:$green-color;
        }

        @media #{$media-not-desktop} {
            &.pp-first, &.pp-last{
                border-color:$green-color;
            }
        }
    }

    &__red{
        border-color:$red-color;
        .pricing__icon{
            color:$red-color;
        }

        .pricing__content-package{
            color:$red-color;
        }

        @media #{$media-not-desktop} {
            &.pp-first, &.pp-last{
                border-color:$red-color;
            }
        }
    }

    &__orange{
        border-color:$orange-color;
        .pricing__icon{
            color:$orange-color;
        }

        .pricing__content-package{
            color:$orange-color;
        }

        @media #{$media-not-desktop} {
            &.pp-first, &.pp-last{
                border-color:$orange-color;
            }
        }
    }

    &__purple{
        border-color:$purple-color;
        .pricing__icon{
            color:$green-color;
        }

        .pricing__content-package{
            color:$purple-color;
        }

        @media #{$media-not-desktop} {
            &.pp-first, &.pp-last{
                border-color:$purple-color;
            }
        }
    }

    &__yellow{
        border-color:$yellow-color;
        .pricing__icon{
            color:$yellow-color;
        }

        .pricing__content-package{
            color:$yellow-color;
        }

        @media #{$media-not-desktop} {
            &.pp-first, &.pp-last{
                border-color:$yellow-color;
            }
        }
    }


    &__grid{
        display: block;
        position: relative;
        background: $white-bg;
        border: 1px solid $gray-color;
        text-align: center;
        box-shadow: 0px 3px 40px 0px rgba(0,0,0,0.15);
        margin-bottom: $padding-elements;

        @media #{$media-not-desktop} {
            margin-bottom: calc($padding-component/2);
        }

        &-header{
            position: relative;
            display: block;
            text-align: center;
            padding: $padding-elements 0 $padding-component 0;
            margin-bottom: $padding-print;
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: 100% auto;
            background-color: $gray-color;
            background-image: url('../../images/pricing-bg-default.png');

            @media all and (-webkit-min-device-pixel-ratio: 1.5) {
                background-image: url('../../images/pricing-bg-default@2x.png');
            }

            @media #{$media-screen-tablet} {
                padding: $padding-elements 0 $padding-section+50 0;
            }

            @media #{$media-screen-mobile} {
                padding: calc($padding-elements/2) 0 calc($padding-elements/2) 0;
            }

            &-title{
                font-family: $font-montserrat;

                @media #{$media-not-desktop} {
                    margin-bottom: $padding-text;
                }
            }

            &--icon{
                @extend %h1;
                font-family: $font-awesome;
                line-height: 48px;
                display: inline-block;
                width: 90px;
                height: 90px;
                overflow: hidden;
                background: $text-color;
                border-radius: 50%;
                color: $white-color;
                padding: $padding-text 0;
                margin-bottom:0;

                @media #{$media-not-desktop} {
                    padding: calc($padding-text/2) 0;
                    line-height: 42px;
                    width: 60px;
                    height: 60px;
                }
            }
        }

        &-price{
            @extend %h4;
            font-family: $font-montserrat-light;
            position: relative;
            display: block;
            margin-bottom: $padding-component;

            &--number{
                position: relative;
                font-size: 54px;
                padding-left: $padding-print;

                @media #{$media-not-desktop} {
                    font-size: 42px;
                }

            }

            &--currency{
                position: absolute;
                font-style: normal;
                font-size:18px;
                top: $padding-print;
                left:0;
            }
        }

        &-content{
            @extend %h5;
            font-family: $font-montserrat;
            text-align: left;
            display: inline-block;
            padding: $padding-elements 0;
            margin:0;

            @media #{$media-not-desktop} {
                padding: calc($padding-elements/2) 0;
            }

            ul{
                margin:0;
                padding:0;

                li{
                    list-style: none;
                    margin:0 0 $padding-print 0;
                    padding:0;

                    @media #{$media-not-desktop} {
                        margin:0 0 calc($padding-print/2) 0;
                    }

                    .fa{
                        margin-right: 5px;
                    }

                    &.included{
                        .fa{
                            color: $green-color;
                        }
                    }

                    &.unincluded{
                        .fa{
                            color: $red-color;
                        }
                    }
                }
            }
        }
        
        &-action{
            position: relative;
            display: block;
            text-align: center;
            margin-bottom: -$padding-column;

            @media #{$media-not-desktop} {
                margin-bottom: -$padding-text
            }
        }

        &-primary{
            .pricing__grid-header{
                background-color: $blue-color;
                background-image: url('../../images/pricing-bg-blue.png');
    
                @media all and (-webkit-min-device-pixel-ratio: 1.5) {
                    background-image: url('../../images/pricing-bg-blue@2x.png');
                }

                &-title{
                    color: $white-color;
                }

                &--icon{
                    background: $white-bg;
                    color: $blue-color;
                }
            }

            .pricing__grid-price{
                color: $white-color;
            }
        }

        &-orange{
            .pricing__grid-header{
                background-color: $orange-color;
                background-image: url('../../images/pricing-bg-orange.png');
    
                @media all and (-webkit-min-device-pixel-ratio: 1.5) {
                    background-image: url('../../images/pricing-bg-orange@2x.png');
                }

                &-title{
                    color: $white-color;
                }

                &--icon{
                    background: $white-bg;
                    color: $orange-color;
                }
            }

            .pricing__grid-price{
                color: $white-color;
            }
        }

        &-green{
            .pricing__grid-header{
                background-color: $green-color;
                background-image: url('../../images/pricing-bg-green.png');
    
                @media all and (-webkit-min-device-pixel-ratio: 1.5) {
                    background-image: url('../../images/pricing-bg-green@2x.png');
                }

                &-title{
                    color: $white-color;
                }

                &--icon{
                    background: $white-bg;
                    color: $green-color;
                }
            }

            .pricing__grid-price{
                color: $white-color;
            }
        }

        &-yellow{
            .pricing__grid-header{
                background-color: $yellow-color;
                background-image: url('../../images/pricing-bg-yellow.png');
    
                @media all and (-webkit-min-device-pixel-ratio: 1.5) {
                    background-image: url('../../images/pricing-bg-yellow@2x.png');
                }

                &-title{
                    color: $white-color;
                }

                &--icon{
                    background: $white-bg;
                    color: $yellow-color;
                }
            }

            .pricing__grid-price{
                color: $white-color;
            }
        }

        &-red{
            .pricing__grid-header{
                background-color: $red-color;
                background-image: url('../../images/pricing-bg-red.png');
    
                @media all and (-webkit-min-device-pixel-ratio: 1.5) {
                    background-image: url('../../images/pricing-bg-red@2x.png');
                }

                &-title{
                    color: $white-color;
                }

                &--icon{
                    background: $white-bg;
                    color: $red-color;
                }
            }

            .pricing__grid-price{
                color: $white-color;
            }
        }

        &-purple{
            .pricing__grid-header{
                background-color: $purple-color;
                background-image: url('../../images/pricing-bg-purple.png');
    
                @media all and (-webkit-min-device-pixel-ratio: 1.5) {
                    background-image: url('../../images/pricing-bg-purple@2x.png');
                }

                &-title{
                    color: $white-color;
                }

                &--icon{
                    background: $white-bg;
                    color: $purple-color;
                }
            }

            .pricing__grid-price{
                color: $white-color;
            }
        }
    }
}
.demo-list{
    margin: 0;
    padding: 0;

    li{
        list-style: none;
        margin: 0 0 2px 0;
        padding: 0;

        a{
            display: block;
            border: 1px solid $text-gray-color;
            padding: 10px 25px;
            color: $black-color;
            font-weight: 700;
        }
    }
}
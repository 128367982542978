.contact{
    &__aside{
        background: $silver-bg;
        height: 100%;
        text-align: right;
        padding: $padding-component;

        @media #{$media-not-desktop} {
            text-align: left;
            padding: calc($padding-component/2) calc($padding-component/2) $padding-text calc($padding-component/2);
            margin-top: calc($padding-component/2);
            margin-bottom: calc($padding-component/2);
            height: auto;
        }

        &--content{
            position: relative;
            z-index: 2;
        }

        &--widget{
            position: relative;
            padding-bottom: $padding-elements;
            margin-bottom: $padding-elements;

            @media #{$media-not-desktop} {
                padding-bottom: calc($padding-elements/2);
                margin-bottom: calc($padding-elements/2);
            }

            p{
                margin: 0;
            }

            &::after{
                content: '';
                position: absolute;
                background: $black-color;
                display: inline-block;
                width: 80px;
                height: 1px;
                border-radius: 3px;
                right: 0;
                bottom: 0;

                @media #{$media-not-desktop} {
                    right: auto;
                    left: 0;
                }
            }

            &-title{
                position: relative;
                padding-right: 25px;

                @media #{$media-not-desktop} {
                    padding-right: 0;
                    padding-left: 25px;
                }

                i{
                    position: absolute;
                    right: 0;
                    top: 3px;

                    @media #{$media-not-desktop} {
                        right: auto;
                        left: 0;
                    }
                }
            }
        }
    }

    &__main{
        padding: $padding-component $padding-column;

        @media #{$media-not-desktop} {
            padding: calc($padding-component/2) 0 0 0;
        }
    }
}
/* Montserrat Fonts */
@font-face {
  font-family: 'Montserrat Light';
  src: url('../../fonts/montserrat/montserrat-light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/montserrat/montserrat-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Medium';
  src: url('../../fonts/montserrat/montserrat-medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('../../fonts/montserrat/montserrat-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat SemiBold';
  src: url('../../fonts/montserrat/montserrat-semi-bold.ttf') format('truetype');
}

/* back to top */
.back-to-top {
    position: fixed;
    z-index:99;
    bottom: 50px;
    right: 50px;
    width: 50px;
    height: 50px;
    color: $black-color;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 2px solid $gray-bg;
    background-color: $gray-bg;
    text-align: center;
    font-size: $font-print;
    cursor: pointer;
    padding-top: 8px;
    display: none;
    -webkit-box-shadow: 0px 15px 16px -5px rgba(150,155,181,0.55);
    -moz-box-shadow: 0px 15px 16px -5px rgba(150,155,181,0.55);
    box-shadow: 0px 15px 16px -5px rgba(150,155,181,0.55);
    transition: all 0.3s ease-in-out;
    &:focus, &:active {
      color: $black-color;
      background-color: $gray-bg;
      border-color: $gray-bg;
    }
    &:hover {
      background: $gray-color;
      border-color: $gray-color;
    }
  
    &.active {
      display: block;
    }
  
    &__icon {
      display: block;
      margin-top:-5px;
      font-size: 24px;
    }
  
    &__text{
      display: block;
      font-size: $font-print;
      line-height:$font-print;
      margin-top:-2px;
    }
    @media #{$media-screen-mobile} {
      right: 5vw;
      bottom: 5vw;
    }
  }
  
.innerpage__head{
    background: $gray-bg;
    color: $white-color;
    padding: $padding-section 0 $padding-print 0;
    margin-bottom: $padding-half;
    border-radius: 0;
    display: block;
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;

    @media #{$media-not-desktop} {
        padding: $padding-section - 8 0 calc($padding-print/2) 0;
        margin-bottom: calc($padding-elements/2);
    }
}